<template>
  <v-container id="OnBoarding" class="h-100 onboarding-modal flex-column-remaining overflow-y-auto">
    <div class="back-button text-right">
      <v-btn icon @click="closeOnBoarding">
        <v-icon color="grey darken-2">mdi-chevron-down</v-icon>
      </v-btn>
    </div>

    <section class="flex-grow-1">
      <b-steps v-model="activeStep" animated :mobile-mode="null" rounded>
        <b-step-item step="1">
          <div class="remaining-space">
            <div class="pb-3 mb-3 header-steps">
              <div class="top-text my-3">
                <h1 class="title is-2 black--text text-center mb-0">Экономьте время</h1>
              </div>
            </div>
            <div class="content">
              <p>Создайте одну заявку для всех эвакуаторов</p>
              <img src="/assets/onboarding_2.png" alt="Рассылка заявки">
            </div>
          </div>
        </b-step-item>

        <b-step-item
          class="flex-column-remaining h-100"
          step="2">
          <div class="remaining-space">
            <div class="content">
              <p class="mt-5">Выберете среди откликов водителя, который подходит вам по цене</p>
              <img src="/assets/onboarding_3.png" alt="Выбор водителя">
            </div>
          </div>
          <div>
            <b-button class="w-100" type="is-success" @click="closeOnBoarding">Закрыть</b-button>
          </div>
        </b-step-item>
      </b-steps>
    </section>
  </v-container>
</template>


<script>

export default {
  name: 'Onboarding',
  data() {
    return {
      activeStep: 0,
    };
  },
  created() {
    if (this.showOnBoarding != true) {
      this.$router.push('/');
    }
  },
  computed: {
    showOnBoarding: {
      get() {
        return this.$store.getters.SHOW_ONBOARDING;
      },
      set(value) {
        this.$store.commit('SET_SHOW_ONBOARDING', value);
      }
    }
  },
  methods: {
    closeOnBoarding() {
      this.showOnBoarding = false;
      this.activeStep = 0; // Сброс шага при закрытии
      this.$router.push('/');
    },
    nextStep() {
      if (this.activeStep < 1) {
        this.activeStep++;
      }
    }
  }
};
</script>

<style>
.onboarding-modal {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.b-steps {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flex-column-remaining {
  display: flex;
  flex-direction: column;
}

.b-steps .step-content {
  flex-grow: 1;
}

.remaining-space {
  flex-grow: 1;
}

.onboarding-modal .media,
.onboarding-modal .media .media-content {
  height: 100%;
}

.header-steps .title.is-3 {
  font-size: 1.17em !important;
}

#OnBoarding p {
  font-size: 1.2em;
}

#OnBoarding {
  max-width: 600px !important;
}

</style>
