import { AxiosClient } from '@/app/shared/services';
import { toFormData } from '../../shared/services/helpers';
import axios from "axios";

const state = {
  selectedBlockTypeOrder: null,
};

const getters = {
  BLOCK_TYPE: (state) => state.selectedBlockTypeOrder,
};

const mutations = {
  SET_BLOCK_TYPE: (state, selectedBlock) => {
    state.selectedBlockTypeOrder = selectedBlock;
  },
};

const actions = {
};


export default {
  state,
  getters,
  mutations,
  actions,
};
