import { AxiosClient } from '@/app/shared/services';

const state = {
  orders: {
    data: [],
  },
  currentOrder: {},
};

const getters = {
  ORDERS: (state) => state.orders.data,
  CURRENT_ORDER: (state) => state.currentOrder,
  TEXT_DATE: (state) => (text) => {
    return new Date(text).toLocaleDateString('ru-RU', { day: 'numeric', month: 'long' });
  },
  REPLACE_BREAK: (state) => (text) => {
    return text.replace(/\n/g, '<br>');
  }
};

const mutations = {
  SET_ORDERS: (state, orders) => {
    state.orders.data = orders;
  },
  SET_CURRENT_ORDER: (state, order) => {
    state.currentOrder = order;
  }
};

const actions = {
  GET_ORDERS: (context) => {
    return new Promise((resolve, reject) => {
      AxiosClient.get("/orders/history")
        .then((data) => {
          context.commit("SET_ORDERS", data.data);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка:\n', error);
          reject();
        });
    });
  },
  GET_CURRENT_ORDER: (context, orderId) => {
    return new Promise((resolve, reject) => {
      AxiosClient.get("/orders/" + orderId + "/")
        .then((data) => {
          context.commit("SET_CURRENT_ORDER", data.data);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка:\n', error);
          reject();
        });
    });
  },
};


export default {
  state,
  getters,
  mutations,
  actions,
};
