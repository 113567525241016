import Guard from "vue-router-multiguard";
import {Middlewares} from "@/app/shared/services";

export default [
  {
    path: "/order/create/1",
    name: "orderCreate",
    meta: {
      title: "Создать заказ",
    },
    component: () => import("./views/orderCreatePage"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/order/create/2",
    name: "orderCreate2",
    meta: {
      title: "Создать заказ",
    },
    component: () => import("./views/orderCreatePage2"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
];
