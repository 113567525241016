<template>

  <b-notification
    v-if="showCookiesNotification == true && showOnBoarding != true"
    aria-close-label="Close notification"
    :has-icon="false"
    class="cookie-notification"
    @close="confirmCookies"
  >
    <div class="content" style="overflow-x: auto;">
      <p>
        Этот сайт использует файлы cookie для хранения данных.
        Продолжая использовать сайт, вы даёте согласие на работу с этими файлами.<br>
        <a href="https://документы.чтовезем.рф/documents/CookiePolicy">Подробнее...</a>
      </p>
    </div>
    <div class="buttons">
      <b-button type="is-light" @click="confirmCookies">Принять</b-button>
    </div>
  </b-notification>

</template>

<script>

export default {
  name: 'CookiesConfirm',
  data() {
    return {
    };
  },
  computed: {
    showOnBoarding() {
      return this.$store.getters.SHOW_ONBOARDING;
    },
    showCookiesNotification: {
      get() {
        return this.$store.getters.SHOW_COOKIES_NOTIFICATION;
      },
      set(value) {
        this.$store.commit('SET_SHOW_COOKIES_NOTIFICATION', value);
      }
    }
  },
  methods: {
    confirmCookies() {
      this.showCookiesNotification = false;
    }
  }
};
</script>

<style>
.cookie-notification {
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 420px !important;
  z-index: 1000;
}

.cookie-notification .content p {
  font-size: 14px;
  margin: 0 !important;
}

.notification {
  background-color: #e1e1e1 !important;
}

@media screen and (min-width: 440px) {
  .notification {
    left: 10px;
    bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .media-content {
    overflow-x: unset !important;
  }
}

</style>
