import { AxiosClient } from '@/app/shared/services';

const state = {
  offers: {
    data: [],
  },
  order_partner: {}
};

const getters = {
  OFFERS: (state) => state.offers.data,
  ORDER_PARTNER: (state) => state.order_partner
};

const mutations = {
  SET_OFFERS: (state, requests) => {
    state.offers.data = requests;
  },
  SET_ORDER_PARTNER: (state, parner) => {
    state.order_partner = parner;
  }
};

const actions = {
  GET_OFFERS: (state, idOrder) => {
    return new Promise((resolve, reject) => {
      AxiosClient.get("/offers/" + idOrder + "/")
        .then((data) => {
          state.commit("SET_OFFERS", data.data);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка получения предложений:\n', error);
          reject();
        });
    });
  },
  CONFIRM_TO_OFFER: (state, idOffer) => {
    return new Promise((resolve, reject) => {
      AxiosClient.post("/offers/" + idOffer + "/actions/confirm")
        .then((data) => {
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка согласия:\n', error);
          reject();
        });
    });
  },
  CANCEL_ORDER: (state, id) => {
    return new Promise((resolve, reject) => {
      AxiosClient.get("/orders/cancel/" + id)
        .then((data) => {
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка отмены заказа:\n', error);
          reject();
        });
    });
  },
  GET_ORDER_PARTNER: (context, orderId) => {
    return new Promise((resolve, reject) => {
      AxiosClient.get("/orders/" + orderId + "/partner")
        .then((data) => {
          context.commit("SET_ORDER_PARTNER", data.data);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка:\n', error);
          reject();
        });
    });
  }
};


export default {
  state,
  getters,
  mutations,
  actions,
};
