import Vue2Filters from "vue2-filters";
import VueTheMask from "vue-the-mask";
import Vue from 'vue';
import Buefy from 'buefy';
import App from './app/app.vue';
import store from './app/app-state';
import vuetify from './plugins/vuetify';
import router from './app/app-routes';
import VueYandexMetrika from 'vue-yandex-metrika'
import Toaster from './plugins/toaster';
import VuePageTransition from 'vue-page-transition';
import FullScreenLoading from './app/shared/components/loading/FullScreenLoading.vue';

import 'buefy/dist/buefy.css';
import './buefy-variables.scss';
import 'vuetify/dist/vuetify.min.css';

import * as PusherPushNotifications from "@pusher/push-notifications-web";
import packageInfo from '../package.json';  // Импортируем package.json


Vue.use(Buefy);
Vue.use(Toaster);
Vue.use(VueTheMask);
Vue.use(Vue2Filters);
Vue.use(VuePageTransition);

// Регистрируем компонент глобально
Vue.component('FullScreenLoader', FullScreenLoading);


Vue.use(VueYandexMetrika, {
  id: process.env.VUE_APP_YANDEX_METRIKA_ID,
  router: router,
  env: "production",
  // debug: true
})

// Делаем версию доступной глобально
Vue.prototype.$version = packageInfo.version;


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  data() {
    return {
      pushInitialized: false,
    };
  },
  mounted() {
    this.initializePushNotifications();
  },
  methods: {
    initializePushNotifications() {

      if (this.pushInitialized) {
        return; // Если пуши уже инициализированы, выходим
      }

      var userId = store.getters.USER_ID;

      if (userId) {
        console.log('Получаем токен для пушей');

        // Устанавливаем флаг инициализации
        this.pushInitialized = true;
        const beamsClient = new PusherPushNotifications.Client({
          instanceId: "aec33ae0-d381-4d61-af3f-991d67e4c1de",
        });

        const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
          url: process.env.VUE_APP_BACKEND_PATH + '/users/notification/subscribe2',
          queryParams: {user_id: userId}
        });

        beamsClient
          .start()
          .then((beamsClient) => beamsClient.setUserId(userId, beamsTokenProvider))
          .then((deviceId) => {
            console.log('Successfully authenticated with Beams!');
          })
          .catch((error) => {
            console.error('Ошибка Beams или уже инициализировано');
          });
      } else {
        // Watch for USER_ID changes
        this.$store.watch(
          (state, getters) => getters.USER_ID,
          (newUserId) => {
            if (newUserId) {
              this.initializePushNotifications();
            }
          }
        );
      }
    },
  }
}).$mount('#app');
