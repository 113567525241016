import Axios from 'axios';
import store from "@/app/app-state";

// Пути, для которых не нужно выполнять повторный запрос
const excludedPaths = ['/api/v1/users/verify'];

Axios.defaults.baseURL = process.env.VUE_APP_BACKEND_PATH;

const authToken = localStorage.getItem("token");
console.log('authToken (middleware): ', authToken);

if (!Axios.defaults.headers.common.token) {
  if (authToken) {
    Axios.defaults.headers.common.token = authToken;
  } else {
    Axios.defaults.headers.common.token = '';
  }
}


// Сохраняем заголовки запроса в локальной переменной
const requestHeaders = Axios.defaults.headers;
const AxiosClient = Axios.create();

function waitForTokenChange() {
  return new Promise((resolve) => {
    const checkToken = () => {
      if (localStorage.getItem('token') !== store.getters.AUTH_TOKEN) {
        resolve();
      } else {
        setTimeout(checkToken, 100); // Проверяем каждые 100 мс
      }
    };
    checkToken();
  });
}

AxiosClient.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401) {
      const requestPath = error.config.url;

      // Проверяем, не находится ли путь в списке исключений
      const isExcludedPath = excludedPaths.some(path => requestPath.includes(path));

      if (!isExcludedPath) {
        console.log('Получен код 401, ждем обновления токена...');
        await waitForTokenChange();

        // Повторяем запрос с новым токеном
        console.log('Повторяем запрос с новым токеном');
        error.config.headers.common.token = `${store.getters.AUTH_TOKEN}`;
        return AxiosClient(error.config);
      }
    }

    return Promise.reject(error);
  }
);


export default AxiosClient;
