<template>
  <div class="columns h-100">
    <div class="column is-12 h-100 center-flex d-flex">
      <v-main>

        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12" md="8">
              <div class="text-center">
                <h2 class="title is-3 mb-2">Кажется, сервер не отвечает</h2>
                <h2 class="title is-4 mb-2">
                  Попробуйте позже или напишите нам сюда:
                  <a href="mailto:uralservisegroup@yandex.ru">uralservisegroup@yandex.ru</a>
                  <br>Нам очень важно знать о проблемах
                </h2>
                <b-button @click="mainPage" type="is-success is-light">
                  На главную
                </b-button>
              </div>
            </v-col>
          </v-row>
        </v-container>

      </v-main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageServerError',
  data() {
    return {
    };
  },
  methods: {
    mainPage() {
      this.$router.push('/');
    },
  },
};

</script>

<style scoped>
.v-application .title.is-4 {
  font-size: 1.10rem!important;
  font-weight: 400;
}

.v-application .title.is-3 {
  font-size: 1.35rem !important;
  font-weight: 500;
}
</style>
