import Guard from "vue-router-multiguard";
import {Middlewares} from "@/app/shared/services";

export default [
  {
    path: "/orders/search",
    name: "tSearchOrders",
    meta: {
      title: "Поиск заказов",
    },
    component: () => import("./views/orderListTransporter.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/orders/history",
    name: "historyOrders",
    meta: {
      title: "История заказов",
    },
    component: () => import("./views/historyOrders.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    // параметр числовой
    path: "/orders/response/:id(\\d+)",
    name: "tCreateOrderResponse",
    meta: {
      title: "Ответить на заказ",
    },
    component: () => import("./views/createOrderResponse.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/orders/transporter/",
    name: "tOrders",
    meta: {
      title: "Ваши перевозки",
    },
    component: () => import("./views/yourTransportation.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/offers/",
    name: "tOffers",
    meta: {
      title: "Ваши предложения",
    },
    component: () => import("./views/offersList.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/cancellation-of-order-transporter/",
    name: "tCancellationOfOrder",
    meta: {
      title: "Отмена заказа",
    },
    component: () => import("./views/cancellationOfOrder.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
];
