import Vue from "vue";
import Vuetify, { VBtn, VIcon, VCard, VThemeProvider } from "vuetify/lib";
import ru from "vuetify/es5/locale/ru";

Vue.use(Vuetify, {
    components: {
        VBtn,
        VIcon,
        VCard,
        VThemeProvider
    },
});

export default new Vuetify({
    lang: {
        locales: { ru },
        current: "ru",
    },
    icons: {
        iconfont: "mdi",
    },
});
