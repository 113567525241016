import { AxiosClient } from '@/app/shared/services';
import store from "@/app/app-state";

const state = {
  isDeveloper: localStorage.getItem("isDeveloper") || false,
};

const getters = {
  IS_DEVELOPER(state) {
    return state.isDeveloper;
  },
};

const mutations = {
  SET_IS_DEVELOPER(state, isDeveloper) {
    state.isDeveloper = isDeveloper;
    localStorage.setItem("isDeveloper", isDeveloper);
  },
};

const actions = {
  CHANGE_TYPE_PROFILE: (context) => {
    new Promise((resolve, reject) => {
      AxiosClient.put("/dev-utils/account_type")
        .then((data) => {
          context.commit("SET_ACCOUNT_TYPE", data.data.account_type);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка смена типа аккаунта:\n', error);
          reject();
        });
    });
  },
};


export default {
  state,
  getters,
  mutations,
  actions,
};
