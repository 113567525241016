<template>
  <div class="columns h-100">
    <div class="column is-12 h-100 center-flex d-flex">
      <v-main>

        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12" md="8">
              <div class="text-center">
                <h2 class="title is-3 mb-2">Страница не найдена</h2>
                <b-button @click="mainPage" type="is-success is-light">
                  На главную
                </b-button>
              </div>
            </v-col>
          </v-row>
        </v-container>

      </v-main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page404',
  data() {
    return {
      // Флаг для отслеживания перенаправления пользователя
      redirected: false
    };
  },
  mounted() {
    setTimeout(() => {
      if (!this.redirected) {
        this.mainPage();
      }
    }, 5000);
    // Вызов метода mainPage через 5 секунд, если пользователь не перешел на другую страницу
  },
  beforeRouteLeave(to, from, next) {
    // Устанавливаем флаг redirected в true перед переходом на другую страницу
    this.redirected = true;
    next();
  },
  methods: {
    mainPage() {
      this.$router.push({ path: '/' }).catch(()=>{});
    },
  },
};

</script>
