import Guard from "vue-router-multiguard";
import {Middlewares} from "@/app/shared/services";

export default [
  {
    path: "/orders",
    name: "orders",
    meta: {
      title: "Ваши заказы",
    },
    component: () => import("./views/orderList"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    // параметр числовой
    path: "/orders/:id(\\d+)",
    name: "orderInfo",
    meta: {
      title: "Информация о заказе",
    },
    component: () => import("./views/orderInfo"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
];
