import { AxiosClient } from '@/app/shared/services';

const state = {
  selectedBlockTypeOrder: null,
  counterResponse: 0,
  isActiveOrder: false,
  activeOrder: null,
  counterOffersOfOrders: 0,
};

const getters = {
  ACTIVE_ORDER_COUNTER: (state) => state.counterOffersOfOrders,
  ACTIVE_ORDER: (state) => state.activeOrder,
  IS_ACTIVE_ORDER: (state) => state.isActiveOrder,
};

const mutations = {
  SET_ACTIVE_ORDER: (state, activeOrder) => {
    if (activeOrder.length === 0) {
      state.isActiveOrder = false;
    } else {
      state.isActiveOrder = true;
      state.activeOrder = activeOrder[0];
      state.counterOffersOfOrders = activeOrder[0].offers_count;
    }
  }
};

const actions = {
  GET_ACTIVE_ORDER: (state) => {
    return new Promise((resolve, reject) => {
      AxiosClient.get("/users/orders/active")
        .then((data) => {
          state.commit("SET_ACTIVE_ORDER", data.data);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка получения активного заказа:\n', error);
          reject();
        });
    });
  },
};


export default {
  state,
  getters,
  mutations,
  actions,
};
