import Vue from "vue";
import Router from "vue-router";

import { PageNotFound, PageServerError, AboutProgram, OnBoarding } from "./shared/components";
import clientRoutes from "@/app/client/client-routes";

const baseRoutes = [
  {
    path: '/',
    redirect: '/main',
  },
  {
    path: "/404",
    name: "Page404",
    meta: {
      title: "Страница не найдена",
    },
    component: PageNotFound,
  },
  {
    path: "/server-error",
    name: "PageServerError",
    meta: {
      title: "Сервер недоступен",
    },
    component: PageServerError,
  },
  {
    path: "/about-program",
    name: "AboutProgram",
    meta: {
      title: "О программе",
    },
    component: AboutProgram,
  },
  {
    path: "/onboarding",
    name: "OnBoarding",
    meta: {
      title: "Заказать эвакуатор",
    },
    component: OnBoarding,
  }
];

Vue.use(Router);
const router = new Router({
    mode: "history",
    routes: [
        ...baseRoutes,
        ...clientRoutes,
        {
            path: "*",
            redirect: "/404",
        },
    ],
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
});

export default router;
