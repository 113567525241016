import Guard from "vue-router-multiguard";
import {Middlewares} from "@/app/shared/services";

export default [
  {
    path: "/login-transporter",
    name: "loginTransporter",
    meta: {
      title: "Вход: перевозчик",
    },
    beforeEnter: Guard([Middlewares.authRequired]),
    component: () => import("./views/loginTransporter"),
  },
  {
    path: "/application-registration",
    name: "applicationRegistration",
    meta: {
      title: "Заявка на статус перевозчика",
    },
    beforeEnter: Guard([Middlewares.authRequired]),
    component: () => import("./views/applicationRegistration.vue"),
  },
  {
    path: "/status-request-for-transporter",
    name: "statusRequestForTransporter",
    meta: {
      title: "Заявка на статус перевозчика",
    },
    beforeEnter: Guard([Middlewares.authRequired]),
    component: () => import("./views/statusRequestForTransporter.vue"),
  },
];
