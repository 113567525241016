import { AxiosClient } from '@/app/shared/services';
import axios from 'axios';

const state = {
    suggestions_from: {
      data: [],
    },
    suggestions_to: {
      data: [],
    },

  // TODO: перенести адреса в заказ, чтобы не собирать json
    address_from: "",
    address_to: "",

    order: {
      car_mark: null,
      car_model: null,
      number_vehicle: '',
      comment: '',
      dont_have_a_number: false,
      dont_have_this_model: false
    },

    orderReadyData: {},

    car_marks: [],
    car_models: []

};

const getters = {
  SUGGESTIONS_FROM: (state) => state.suggestions_from.data,
  SUGGESTIONS_TO: (state) => state.suggestions_to.data,
  ADDRESS_TO: (state) => state.address_to,
  ADDRESS_FROM: (state) => state.address_from,
  ORDER: (state) => state.order,
  CAR_MARKS: (state) => state.car_marks,
  CAR_MODELS: (state) => state.car_models,
  ORDER_DATA_READY: (state) => state.orderReadyData
};

const mutations = {
  SET_ADDRESSES_FROM: (state, new_addresses) => {
    state.suggestions_from.data = new_addresses;
  },
  SET_ADDRESSES_TO: (state, new_addresses) => {
    state.suggestions_to.data = new_addresses;
  },
  SET_ADDRESS_TO: (state, new_address) => {
    state.address_to = new_address;
  },
  SET_ADDRESS_FROM: (state, new_address) => {
    state.address_from = new_address;
  },
  SET_ORDER: (state, new_order) => {
    state.order = new_order;
  },
  SET_CAR_MARKS: (state, new_cars) => {
    state.car_marks = new_cars;
  },
  SET_CAR_MODELS: (state, new_cars) => {
    state.car_models = new_cars;
  },
  SET_DEFAULT_ORDER: (state) => {
    state.order = {
      car_model: null,
      car_mark: null,
      number_vehicle: '',
      comment: '',
      dont_have_a_number: false,
      dont_have_this_model: false
    };
    state.address_from = "";
    state.address_to = "";
  },
  SET_ORDER_DATA_READY: (state, data) => {
    state.orderReadyData = data;
  }
};

const actions = {

  GET_ADDRESSES_STRING: (context, payload) => {
    new Promise((resolve, reject) => {
      AxiosClient.get("/suggestions/address", { params: { query: payload.text } })
        .then((response) => {
          if (payload.type) {
            context.commit("SET_ADDRESSES_FROM", response.data);
          } else {
            context.commit("SET_ADDRESSES_TO", response.data);
          }
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка запроса адресов: ', error);
          reject();
        });
    });
  },
  CREATE_ORDER: (context) => {
    console.log('Данные для нового заказа: ', context.getters.ORDER_DATA_READY);
    return new Promise((resolve, reject) => {
      AxiosClient.post("/orders/", context.getters.ORDER_DATA_READY)
        .then((response) => {
          console.log('add order: \n', response.data);
          console.info('Заказ успешно создан!');
          context.commit("SET_DEFAULT_ORDER");
          context.commit('SET_ORDER_DATA_READY', {})
          resolve();
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
            const errorMessage = error.response.data.message;
            if (errorMessage === "You have already created an order") {
              reject('active order');
            } else {
              console.error('Ошибка при создании заказа:\n', error);
            }
          } else {
            console.error('Ошибка при создании заказа:\n', error);
          }
          reject('error');
        });
    });
  },
  GET_CAR_MARKS: (context, query) => {
    return new Promise((resolve, reject) => {
      AxiosClient.post("/suggestions/car_marks/", query)
        .then((data) => {
          context.commit("SET_CAR_MARKS", data.data);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка:\n', error);
          reject();
        });
    });
  },
  GET_CAR_MODELS: (context, query) => {
    return new Promise((resolve, reject) => {
      AxiosClient.post("/suggestions/car_models/", query)
        .then((data) => {
          context.commit("SET_CAR_MODELS", data.data);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка:\n', error);
          reject();
        });
    });
  },
};


export default {
  state,
  getters,
  mutations,
  actions,
};
