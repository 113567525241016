import Guard from "vue-router-multiguard";
import {Middlewares} from "@/app/shared/services";

export default [
  {
    path: "/profile",
    name: "profile",
    meta: {
      title: "Профиль",
    },
    component: () => import("./views/profilePage"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
];
