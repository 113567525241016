import Vue from "vue";
import Vuex from "vuex";

import {
    main_page,
    order_list,
    order_page,
    activity_feed,
    request_page,
    profile_page,
    login_transporter_page,
    order_list_transporter_page,
    authorization_page,
} from "./client/client-states";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        main_page,
        order_list,
        order_page,
        activity_feed,
        request_page,
        profile_page,
        login_transporter_page,
        order_list_transporter_page,
        authorization_page,
    },
});
