import Guard from "vue-router-multiguard";
import { Middlewares } from "@/app/shared/services/app-router/middlewares";

export default [
  {
    path: "/main",
    name: "main",
    meta: {
      title: "Заказать эвакуатор",
    },
    component: () => import("./views/main"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
];
