<template>
  <div>
    <div id="app">
      <v-app>
        <div class="page-layout">
          <vue-page-transition name="fade-in-down">
            <router-view/>
          </vue-page-transition>
        </div>
      </v-app>
    </div>
    <CookiesConfirm />
  </div>
</template>

<script>
import styles from './styles_tables.css';
import { CookiesConfirm } from './shared/components/';
import store from "@/app/app-state";

export default {
  name: "App",
  components: {
    CookiesConfirm
  },
  created() {
    if (!store.getters.AUTH_SEND_REQUEST) {
      store.commit("SET_AUTH_SEND_REQUEST", true);
      this.$store.dispatch("AUTH").finally(() => {
        store.commit("SET_AUTH_SEND_REQUEST", false);
      });
    }
  },
  data() {
      return {
      };
  },
  methods: {
  }
};
</script>

<style>
body {
    font-family: "Fira Sans Condensed", "Roboto", Arial, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fbfbfb;
    overflow: hidden;
}

.page-layout {
    height: calc(100% - 20px);
    margin: 10px 10px 10px;
}

.page-layout > div {
  height: 100%;
}

</style>
