import Guard from "vue-router-multiguard";
import { Middlewares } from "@/app/shared/services/app-router/middlewares";

export default [
  {
    path: "/requests",
    name: "requestList",
    meta: {
      title: "Отклики",
    },
    component: () => import("./views/requestList"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/cancellation-of-order",
    name: "cancellationOfOrder",
    meta: {
      title: "Отмена заказа",
    },
    component: () => import("./views/cancellationOfOrder.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/order/contacts-partner/:id(\\d+)",
    name: "contactsPartner",
    meta: {
      title: "Контакты для связи",
    },
    component: () => import("./views/contactsPartner.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
];
