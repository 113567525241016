import Guard from "vue-router-multiguard";
import { Middlewares } from "@/app/shared/services/app-router/middlewares";

export default [
  {
    path: "/token",
    name: "tokenYa",
    meta: {
      title: "Получение токена от Яндекса",
    },
    component: () => import("./views/tokenPage"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/registration",
    name: "registration",
    meta: {
      title: "Регистрация",
    },
    component: () => import("./views/registration.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/easy-registration",
    name: "easyRegistration",
    meta: {
      title: "Регистрация",
    },
    component: () => import("./views/easy-registration.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/verification-code",
    name: "verificationCode",
    meta: {
      title: "Подтверждение телефона",
    },
    component: () => import("./views/verification_code.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/send-verification-code",
    name: "sendVerificationCode",
    meta: {
      title: "Подтвердите номер телефона",
    },
    component: () => import("./views/send_verification_code.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/confirm-success",
    name: "confirmSuccess",
    meta: {
      title: "Аккаунт подтверждён",
    },
    component: () => import("./views/confirm_success.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    meta: {
      title: "Восстановление пароля",
    },
    component: () => import("./views/forgot_password.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  }
];
