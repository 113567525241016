<template>
  <v-overlay :value="show" z-index="100">
    <v-progress-circular :size="80" color="spinner" indeterminate></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'OverlayLoader',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    show(newValue) {
      if (newValue) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    }
  },
  mounted() {
    if (this.show) {
      document.body.classList.add('no-scroll');
    }
  },
  beforeUnmount() {
    document.body.classList.remove('no-scroll');
  }
}
</script>

<style scoped>
</style>
