import { AxiosClient } from '@/app/shared/services';

const state = {
  activeOrdersTr: {},
  counterOrdersTr: 0,
  historyOrdersTransporter: {},
  availableOrdersTr: {},
  offers_history: {},
  cansel_data: {},
};

const getters = {
  // ORDERS_TRANSPORTER: (state) => state.orders_transporter.data,
  // HISTORY_TRANSPORTER: (state) => state.history_transporter.data,
  ACTIVE_ORDERS_TRANSPORTER: (state) => state.activeOrdersTr,
  ACTIVE_ORDERS_TRANSPORTER_COUNTER: (state) => state.counterOrdersTr,
  HISTORY_ORDERS_TRANSPORTER: (state) => state.historyOrdersTransporter,
  AVAILABLE_ORDERS_TRANSPORTER: (state) => state.availableOrdersTr,
  OFFERS_HISTORY: (state) => state.offers_history,
  CANCEL_DATA: (state) => state.cansel_data
};

const mutations = {
  SET_OFFERS_HISTORY: (state, offers) => {
    state.offers_history = offers;
  },
  SET_ACTIVE_ORDERS_TRANSPORTER: (state, activeOrders) => {
    state.activeOrdersTr = activeOrders.orders;
    state.counterOrdersTr = activeOrders.count;
  },
  SET_ORDERS_HISTORY_TRANSPORTER: (state, orders) => {
    state.historyOrdersTransporter = orders;
  },
  SET_AVAILABLE_ORDERS_TRANSPORTER: (state, orders) => {
    state.availableOrdersTr = orders;
  },
  SET_CANCEL_DATA: (state, data) => {
    state.cansel_data = data;
  }
};

const actions = {
  GET_HISTORY_TRANSPORTER: (state) => {
    return new Promise((resolve, reject) => {
      AxiosClient.get("/transporters/orders/history")
        .then((data) => {
          state.commit("SET_ORDERS_HISTORY_TRANSPORTER", data.data);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка получения истории заказов (перевозчик):\n', error);
          reject();
        });
    });
  },
  GET_ACTIVE_ORDERS_TRANSPORTER: (state) => {
    return new Promise((resolve, reject) => {
      AxiosClient.get("/transporters/orders/active")
        .then((data) => {
          state.commit("SET_ACTIVE_ORDERS_TRANSPORTER", data.data);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка получения активного заказа (перевозчик):\n', error);
          reject();
        });
    });
  },
  GET_AVAILABLE_ORDERS_TRANSPORTER: (state) => {
    return new Promise((resolve, reject) => {
      AxiosClient.get("/transporters/orders/available")
        .then((data) => {
          state.commit("SET_AVAILABLE_ORDERS_TRANSPORTER", data.data);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка получения свободных заказов (перевозчик):\n', error);
          reject();
        });
    });
  },
  CREATE_OFFER: (context, newOffer) => {
    console.log('Данные для нового заказа: ', newOffer);
    return new Promise((resolve, reject) => {
      AxiosClient.post("/offers/", newOffer)
        .then((response) => {
          console.log('add offer: \n', response.data);
          console.info('Оффер успешно создан!');
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка при создании оффера:\n', error);
          reject();
        });
    });
  },
  GET_OFFERS_HISTORY: (state) => {
    return new Promise((resolve, reject) => {
      AxiosClient.get("/transporters/offers/history")
        .then((data) => {
          state.commit("SET_OFFERS_HISTORY", data.data);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка получения истории офферов:\n', error);
          reject();
        });
    });
  },
  CHANGE_STATUS: (context, data) => {
    return new Promise((resolve, reject) => {
      let status = data.status;
      let order_id = data.order_id;

      AxiosClient.get("/transporters/orders/" + order_id + "/status_change", {params: {status: status}})
        .then(() => {
          console.info('Статус успешно изменен!');
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка при изменении статуса:\n', error);
          reject();
        });
    });
  },
};


export default {
  state,
  getters,
  mutations,
  actions,
};
