import { AxiosClient } from "@/app/shared/services";
import store from "@/app/app-state";
import * as PusherPushNotifications from '@pusher/push-notifications-web';

const state = {
  auth: {
    token: localStorage.getItem("token") || undefined,
    user_id: localStorage.getItem("user_id") || undefined,
    account_type: localStorage.getItem("account_type") || undefined,
    auth_type: localStorage.getItem("auth_type") || undefined,
    authUser: {},
    details: {},
    extra_info: {},
    balance: 0
  },
  auth_send_request: false,
  reg_from: null,

  showCookiesNotification: localStorage.getItem("cookies_notification") || true,
  showOnBoarding: localStorage.getItem("onboarding") || true,

  // ПРАВИЛА ДЛЯ ПАРОЛЯ И Т.П.
  passwordRules: [
    v => !!v || 'Введите пароль',
    v => (v && v.length >= 8) || 'Пароль должен содержать минимум 8 символов',
    v => /^[A-Za-z0-9@$!%*?&-.]+$/.test(v) || 'Пароль должен содержать буквы латинского алфавита, цифры или специальные символы (@$!%*?&-.)',
    v => /[A-Z]/.test(v) || 'Пароль должен содержать минимум одну заглавную букву',
    v => /[0-9]/.test(v) || 'Пароль должен содержать минимум одну цифру'
  ],
  loginRules: [
    v => !!v || 'Введите логин',
    v => /^[a-zA-Z0-9_]+$/.test(v) || 'Логин должен содержать только латинские буквы, цифры или нижнее подчёркивание'
  ],
  phoneRules: [
    v => !!v || 'Телефон обязателен',
    v => /^\d{10}$/.test(v) || 'Телефон состоит из 10 цифр',
  ],
  nameRules: [
    v => !!v || 'Имя обязательно',
    v => (v && v.length <= 30) || 'Имя должно быть меньше 30 символов',
  ],
  emailRules: [
    v => !v || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(v) || 'Введите корректный email адрес'
  ],
  codeRules: [
    v => !!v || 'Код подтверждения обязателен',
    v => /^\d{4}$/.test(v) || 'Код подтверждения состоит из 4 цифр',
  ],
  numberVehicleRules: [
    v => !!v || 'Номер обязателен',
    v => /^[A-Za-z0-9а-яА-Я]{1,10}$/.test(v) || 'Должен содержать до 10 символов',
    v => /^[A-Za-z0-9а-яА-Я]+$/.test(v) || 'Может содержать только буквы (латиницу и кириллицу) и цифры'
  ],
  amountRules: [
    v => !!v || 'Обязательно для заполнения',
    v => v > 0 || 'Сумма должна быть больше нуля',
    v => Number.isInteger(+v) || 'Сумма должна быть целым числом'
  ],
  phone_for_forgot_password: null
};

const getters = {
  AUTH_TOKEN: (state) => state.auth.token || localStorage.getItem("token"),
  USER_ID: (state) => state.auth.user_id || localStorage.getItem("user_id"),
  ACCOUNT_TYPE: (state) => state.auth.account_type || localStorage.getItem("account_type"),
  AUTH_TYPE: (state) => state.auth.auth_type || localStorage.getItem("auth_type"),
  PROFILE_DETAILS: (state) => state.auth.details,
  PROFILE_EXTRA_INFO: (state) => state.auth.extra_info,

  SHOW_COOKIES_NOTIFICATION: (state) => state.showCookiesNotification || localStorage.getItem("cookies_notification"),
  SHOW_ONBOARDING: (state) => state.showOnBoarding || localStorage.getItem("onboarding"),

  REGISTRATION_FROM: (state) => state.reg_from,
  AUTH_SEND_REQUEST: (state) => state.auth_send_request,

  // ПРАВИЛА ДЛЯ ПАРОЛЯ И Т.П.
  PASSWORD_RULES: (state) => state.passwordRules,
  LOGIN_RULES: (state) => state.loginRules,
  PHONE_RULES: (state) => state.phoneRules,
  NAME_RULES: (state) => state.nameRules,
  EMAIL_RULES: (state) => state.emailRules,
  CODE_RULES: (state) => state.codeRules,
  NUMBER_VEHICLE_RULES: (state) => state.numberVehicleRules,
  AMOUNT_RULES: (state) => state.amountRules,

  REG_LOCAL_DATA: (state) => {
    const localData = localStorage.getItem("reg_data");
    try {
      return JSON.parse(localData) || {};
    } catch (error) {
      console.error('Ошибка при парсинге данных из localStorage:', error);
      return {};
    }
  },

  BALANCE: (state) => state.auth.balance,

  PHONE_FOR_FORGOT_PASSWORD: (state) => state.phone_for_forgot_password
};

const mutations = {
  SET_REG_DATA: (state, payload) => {
    localStorage.setItem("reg_data", JSON.stringify(payload));
  },
  SET_AUTH_SEND_REQUEST: (state, payload) => {
    state.auth_send_request = payload;
  },
  SET_AUTH_TOKEN: (state, payload) => {
    state.auth.token = payload;
    localStorage.setItem("token", payload);
    console.log('Установили новый токен в заголовок: ', payload);
    AxiosClient.defaults.headers.common.token = payload;
  },
  SET_AUTH_USER_ID: (state, payload) => {
    state.auth.user_id = payload;
    localStorage.setItem("user_id", payload);
  },
  SET_ACCOUNT_TYPE: (state, payload) => {
    state.auth.account_type = payload;
    localStorage.setItem("account_type", payload);
  },
  SET_AUTH_TYPE: (state, payload) => {
    state.auth.auth_type = payload;
    localStorage.setItem("auth_type", payload);
  },
  SET_SHOW_COOKIES_NOTIFICATION: (state, payload) => {
    state.showCookiesNotification = payload;
    localStorage.setItem("cookies_notification", payload);
  },
  SET_SHOW_ONBOARDING: (state, payload) => {
    state.showOnBoarding = payload;
    localStorage.setItem("onboarding", payload);
  },

  SET_REGISTRATION_FROM: (state, payload) => {
    state.reg_from = payload;
  },
  SET_PHONE_FOR_FORGOT_PASSWORD: (state, payload) => {
    state.phone_for_forgot_password = payload;
  },

  SET_PROFILE_DETAILS: (state, payload) => {
    state.auth.details = payload;
  },
  SET_PROFILE_EXTRA_INFO: (state, payload) => {
    state.auth.extra_info = payload;
  },
  SET_BALANCE: (state, payload) => {
    state.auth.balance = payload;
  }
};

const actions = {
  AUTH: (context) => {
    return new Promise((resolve, reject) => {
      console.info('Пытаемся авторизоваться');
      AxiosClient.get("/users/me")
        .then((data) => {
          context.commit("SET_AUTH_TOKEN", data.data.token);
          context.commit("SET_AUTH_USER_ID", data.data.user_id);
          context.commit("SET_ACCOUNT_TYPE", data.data.account_type);
          context.commit("SET_AUTH_TYPE", data.data.auth_type);
          context.commit("SET_PROFILE_DETAILS", data.data.details);
          context.commit("SET_PROFILE_EXTRA_INFO", data.data.extra_info);
          context.commit("SET_BALANCE", data.data.balance);

          context.dispatch('REINITIALIZE_PUSH_NOTIFICATIONS').then(() => {
            console.log('Успешно обновили push-уведомления');
          }).catch((error) => {
            console.error('Не удалось обновить push-уведомления', error);
          });

          console.info('Авторизация успешна');
          console.log('Токен: ', context.getters.AUTH_TOKEN);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка авторизации: ', error);
          reject(error.response.status);
        });
    });
  },
  LOGOUT: (context) => {
    return new Promise((resolve, reject) => {
      context.commit("SET_AUTH_TOKEN", undefined);
      context.commit("SET_AUTH_USER_ID", undefined);
      context.commit("SET_ACCOUNT_TYPE", undefined);
      context.commit("SET_AUTH_TYPE", undefined);
      context.commit("SET_PROFILE_DETAILS", undefined);
      context.commit("SET_PROFILE_EXTRA_INFO", undefined);
      context.commit("SET_BALANCE", 0);

      context.dispatch("AUTH").then(() => {
        resolve();
      }).catch((error) => {
        console.log('Произошла ошибка при выходе: ', error);
        reject();
      });
    });
  },
  LOGIN_YANDEX: (context, data) => {
    new Promise((resolve, reject) => {
      console.log('Посылаем этот токен: ' + data.access_token);

      AxiosClient.post("/users/login/external", {
        'external_token': data.access_token,
        'provider': 'ya'
      })
        .then((response) => {
          console.log('ОТВЕТ СЕРВЕРА: \n', response.data);
          console.log('Токен: ', response.data.user_token);
          if (response.data.user_token) {
            context.commit("SET_AUTH_TOKEN", response.data.user_token);
            console.log('Токен: ', context.getters.AUTH_TOKEN);
            context.dispatch('AUTH');
          }
          // context.commit("SET_ACCOUNT_TYPE", response.data.account_type);
          console.info('ДАННЫЕ С ЯНДЕКСА ДОБАВЛЕНЫ');
          resolve();
        })
        .catch((error) => {
          this.$buefy.toast.open({
            message: 'Ошибка авторизации через яндекс',
            type: 'is-danger'
          });
          console.error('ОШИБКА ПЕРЕДАЧИ ДАННЫХ С ЯНДЕКСА:\n', error);
          reject();
        });
    });
  },
  GET_YANDEX_TOKEN: (context) => {
    new Promise((resolve, reject) => {
      console.log('Смотрим, есть ли для нас токен');
      console.log('token client (for request): ', process.env.VUE_APP_API_KEY_YANDEX);

      YaAuthSuggest.init(
        {
          client_id: process.env.VUE_APP_API_KEY_YANDEX,
          response_type: 'token',
          redirect_uri: process.env.VUE_APP_REDIRECT_URL_YANDEX
        },
        process.env.VUE_APP_REDIRECT_URL_YANDEX
      )
        .then(({
                 handler
               }) => handler())
        .then(data => {
          console.log('Сообщение с токеном', data);
          store
            .dispatch("LOGIN_YANDEX", data)
            .then(() => {
              console.log('Авторизация через яндекс успешно отправлена!');
              resolve();
            })
            .catch(() => {
              console.error('Ошибка авторизации через яндекс');
              reject();
            });
        })
        .catch(error => {
          console.log('Обработка ошибки запроса токена: ', error);
          reject();
        });

    });
  },
  REGISTRATION: (context, reg_data) => {
    console.log('Данные для регистрации: ', reg_data);
    return new Promise((resolve, reject) => {
      AxiosClient.post("/users/register/", reg_data)
        .then((response) => {
          console.info('Регистрация прошла успешно!');
          resolve();
        })
        .catch((error) => {
          reject(error.response.data.detail);
        });
    });
  },
  LOGIN: (context, params) => {
    return new Promise((resolve, reject) => {
      console.info('Пытаемся авторизоваться');
      AxiosClient.post("/users/verify", params)
        .then((data) => {
          context.commit("SET_AUTH_TOKEN", data.data.user_token);

          context.dispatch('AUTH');

          console.info('Вход по логину-паролю успешен');
          console.log('Токен: ', context.getters.AUTH_TOKEN);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка входа по логину-паролю: ', error);
          console.log('Сообщение: ', error.response.data.message);
          reject(error.response.data.message);
        });
    });
  },
  VERIFICATION_PHONE: (context) => {
    return new Promise((resolve, reject) => {
      console.info('Пытаемся подтвердить телефон');
      resolve();
      AxiosClient.get("/users/verifications/phone")
        .then((data) => {
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка звонка подтверждения: ', error);
          reject();
        });
    });
  },
  CONFIRM_PHONE: (context, code) => {
    return new Promise((resolve, reject) => {
      console.info('Отправляем код подтверждения');
      let payload = {
        code: code
      };

      AxiosClient.post("/users/verifications/phone/confirm", payload)
        .then((data) => {
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка кода подтверждения: ', error);
          reject(error);
        });
    });
  },
  NEW_PAYMENT: (context, amount) => {
    return new Promise((resolve, reject) => {

      let data = {
        amount: amount
      };

      AxiosClient.post("/transporters/payments/add/", data)
        .then((data) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  FORGOT_PASSWORD: (context, number) => {
    return new Promise((resolve, reject) => {

      let data_req = {
        "number": number
      }

      AxiosClient.post("/auth/request-reset-password/", data_req)
        .then((data) => {
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка смена пароля:\n', error);
          reject();
        });
    });
  },
  CONFIRM_PHONE_FOR_FORGOT: (context, data) => {
    return new Promise((resolve, reject) => {
      console.info('Отправляем код подтверждения');
      AxiosClient.post("/auth/reset-password/", data)
        .then((data) => {
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка кода подтверждения: ', error);
          reject(error);
        });
    });
  },

  REINITIALIZE_PUSH_NOTIFICATIONS: (context, uuid) => {
    return new Promise((resolve, reject) => {

      if (uuid) {
        console.log('Получаем токен для пушей');
        const beamsClient = new PusherPushNotifications.Client({
          instanceId: "aec33ae0-d381-4d61-af3f-991d67e4c1de",
        });

        const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
          url: process.env.VUE_APP_BACKEND_PATH + '/users/notification/subscribe2',
          queryParams: {user_id: uuid}
        });

        // сначала отписка, потом подписка по новому токену, который получили
        beamsClient.stop().then(() => {
          console.log('Beams. Подписка на уведомления отключена, перезапуск');
          beamsClient
            .start()
            .then((beamsClient) => beamsClient.setUserId(uuid, beamsTokenProvider))
            .then((deviceId) => {
              console.log('Successfully authenticated with Beams!');
            })
            .catch((error) => {
              console.error('Ошибка Beams или уже инициализировано');
            });
        });
      } else {
        console.log('UUID пуст, пуши не инициализированы');
        resolve();
      }
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
