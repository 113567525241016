<template>

  <!-- Лента пользователя -->
  <v-container id="AboutProgram" class="h-100">

    <div class="back-button text-right">
      <v-btn icon @click="goBack">
        <v-icon color="grey darken-2">mdi-chevron-down</v-icon>
      </v-btn>
    </div>

    <div class="pb-3 mb-3">
      <div class="top-text my-3">
        <h1 class="title is-1 thin black--text">Есть</h1>
        <h1 class="title is-1 black--text">вопрос?</h1>
      </div>
    </div>

    <div class="remaining-space">
      <p class="title is-4 mb-0">
        <br>Версия <span style="color: #fc4e2f;">{{version}}{{getBuildTypeFormat}}</span>
      </p>

      <p class="title is-4">
        <br>Почта:<br>
        <a href="mailto:uralservisegroup@yandex.ru">uralservisegroup@yandex.ru</a>
      </p>

      <p class="title is-4">
        <br>По вопросам пишите<br>
        <a href="https://t.me/vezem_perm">
          <i class="fab fa-telegram-plane"></i> @vezem_perm
        </a>
      </p>

      <p class="title is-4">
        <br>Новости проекта<br>
        <a href="https://t.me/vezem_news">
          <i class="fab fa-telegram-plane"></i> @vezem_news
        </a>
      </p>

      <p class="title is-4" v-if="isTransporter">
        <br>Чат перевозчиков<br>
        <a href="https://t.me/+FuTgy4GmxtVlYTc6">
          <i class="fab fa-telegram-plane"></i> Нажмите для перехода
        </a>
      </p>

    </div>

    <div class="pa-3 pb-0" style="text-align: center;">
      <p class="buttons mb-0 flex justify-center align-center made-in-russia">
        <img
          src="/assets/made_in_russia.png"
          alt="Сделано в России"
          style="width: 50%; max-width: 160px;"
        >
      </p>
    </div>

  </v-container>

</template>

<script>
export default {
  name: 'AboutProgram',
  data() {
    return {
      version: this.$version,
      buildType: process.env.VUE_APP_BUILD_TYPE,
    };
  },
  created() {
  },
  computed: {isTransporter() {
      return this.$store.getters.ACCOUNT_TYPE == 1;
    },
    getBuildTypeFormat() {
      let subStr = this.buildType.substring(0, 1);
      if (subStr) {
        if (subStr === 'p') {
          return '';
        } else {
          return subStr;
        }
      } else {
        // We have a big problems
        return 'h';
      }
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    }
  },
};
</script>

<style scoped>
.block-content img {
  max-width: 100%;
  height: auto;
}

.title.is-1 {
  font-size: 4.5rem !important;
}

.title.is-1.thin {
  font-weight: 300;
}

#AboutProgram.container {
  display: flex;
  flex-direction: column;
}

.remaining-space {
  flex-grow: 1;
}

.made-in-russia {

}

.title.is-4 {
  margin-bottom: 0px !important;
  line-height: normal !important;
}

a {
  color: #363636 !important;
}

.svg-inline--fa.fa-telegram-plane {
  padding-top: 5px;
}

</style>
