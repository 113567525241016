import store from "@/app/app-state";

const Middlewares = {
    authRequired(to, from, next) {
      console.log('auth token: ', store.getters.AUTH_TOKEN);

      let authToken = store.getters.AUTH_TOKEN;
      let userId = store.getters.USER_ID;
      let accountType = store.getters.ACCOUNT_TYPE;
      let authType = store.getters.AUTH_TYPE;

      if ((!authToken || !userId || !accountType || !authType) && !store.getters.AUTH_SEND_REQUEST) {
        store.commit("SET_AUTH_SEND_REQUEST", true);
        store
          .dispatch("AUTH")
          .then(() => {
            setTimeout(() => {
              console.log('Просим авторизацию Яндекса модалкой');
              store.dispatch("GET_YANDEX_TOKEN");
            }, process.env.VUE_APP_TIMEOUT_YANDEX_AUTH * 1000);
            store.commit("SET_AUTH_SEND_REQUEST", false);
            next(undefined);
          })
          .catch((status_code) => {
            console.error('Ошибка авторизации');
            if (status_code === 502) {
              console.log('Сервер упал на авторизации');
              next({ name: "PageServerError" });
            } else {
              next('/404');
            }
          });
      } else {
        next(undefined);
      }

    },
};

export { Middlewares };
