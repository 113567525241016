import page1Routes from '@/app/client/main-page/main-page-routes';

import orderListRoutes from '@/app/client/order-list-page/order-list-routes';
import orderRoutes from '@/app/client/form-create-order/form-create-order-routes';
import feedRoutes from '@/app/client/activity-feed/activity-feed-routes';
import requestRoutes from '@/app/client/requests-page/request-routes';
import profileRoutes from '@/app/client/profile-page/profile-routes';
import loginTransporterRoutes from '@/app/client/loginTransporter/loginTransporter-routes';
import orderListTransporterRoutes from '@/app/client/order-list-transporter-page/order-list-transporter-routes';
import authorizationRoutes from "@/app/client/authorization/authorization-routes";

const clientRoutes = [
  ...page1Routes,
  ...orderListRoutes,
  ...orderRoutes,
  ...feedRoutes,
  ...requestRoutes,
  ...profileRoutes,
  ...loginTransporterRoutes,
  ...orderListTransporterRoutes,
  ...authorizationRoutes
];

export default clientRoutes;
