import { AxiosClient } from '@/app/shared/services';

const state = {
  phone_transporter: null,
  password_transporter: null,
  haveRequestTransporter: localStorage.getItem("haveRequestTransporter") || false,
  numberRequestTransporter: localStorage.getItem("numberRequestTransporter") || null,
};

const getters = {
  PHONE_TRANSPORTER(state) {
    return state.phone_transporter;
  },
  PASSWORD_TRANSPORTER(state) {
    return state.password_transporter;
  },
  HAVE_REQUEST_TRANSPORTER: (state) => state.haveRequestTransporter || localStorage.getItem("haveRequestTransporter"),
  NUMBER_REQUEST_TRANSPORTER: (state) => state.numberRequestTransporter || localStorage.getItem("numberRequestTransporter"),
};

const mutations = {
  SET_PHONE_TRANSPORTER(state, phone) {
    state.phone_transporter = phone;
  },
  SET_PASSWORD_TRANSPORTER(state, password) {
    state.password_transporter = password;
  },
  SET_IS_TRANSPORTER(state, isTransporter) {
    state.isTransporter = isTransporter;
  },
  SET_HAVE_REQUEST_TRANSPORTER: (state, payload) => {
    state.haveRequestTransporter = payload;
    localStorage.setItem("haveRequestTransporter", payload);
  },
  SET_NUMBER_REQUEST_TRANSPORTER: (state, payload) => {
    state.numberRequestTransporter = payload;
    localStorage.setItem("numberRequestTransporter", payload);
  },
};

const actions = {
  CREATE_TRANSPORTER_REGISTRATION: (context, apiData) => {
    return new Promise((resolve, reject) => {
      let dataParams = apiData.apiParams;
      let files = apiData.body;
      console.log('Данные для новой регистрации: ', dataParams);

      // Создание FormData для файлов
      let formData = new FormData();

      // Проверка и добавление файлов в formData
      if (Array.isArray(files) && files.length > 0) {
        files.forEach((file, index) => {
          formData.append(`files`, file);
        });
      } else {
        console.error('Файлы отсутствуют или их количество неверное.');
        reject('Ошибка обработки заявки');
        return;
      }

      AxiosClient.post("/transporters/registration/", formData, {
        params: dataParams
      })
        .then((response) => {
          console.info('Заявка отправлена!');
          resolve(response.data);
        })
        .catch((error) => {
          console.error('Ошибка при создании заявки:\n', error);
          reject();
        });
    });
  },
};


export default {
  state,
  getters,
  mutations,
  actions,
};
