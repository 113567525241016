import Guard from "vue-router-multiguard";
import {Middlewares} from "@/app/shared/services";

export default [
  {
    path: "/activity-feed",
    name: "activityFeed",
    meta: {
      title: "Главная страница",
    },
    component: () => import("./views/activity-feed"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/balance",
    name: "balancePage",
    meta: {
      title: "Баланс",
    },
    component: () => import("./views/balancePage"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/replenishment-of-balance",
    name: "replenishmentOfBalancePage",
    meta: {
      title: "Пополнение баланса",
    },
    component: () => import("./views/replenishmentOfBalancePage"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/documents",
    name: "Documents",
    meta: {
      title: "Документы"
    },
    component: () => import("./views/Documents.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  }
];
